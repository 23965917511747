import React, { useEffect, useState } from 'react';
import "../Website/Website.css";
import { AdmitButton3, AdmitStudentRole, FormInputStudent, FormLable, FormTextAreaStudent } from '../../data/Profile';
import { Header } from '../../components';
import Selector from '../../data/Selector';
import { Show } from '../../data/Alerts';
import { apiServer, ShortName } from '../../data/Endpoint';
import { AES, enc } from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { TfiLayoutSlider } from 'react-icons/tfi';
import { FaCar, FaEdit } from 'react-icons/fa';
import { MdAddTask, MdAssignmentAdd, MdDelete } from 'react-icons/md';
import HydotTable from '../../data/HydotTable';
import {
  Stepper, Step, StepLabel, Button, Typography, Box
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import { GiBookCover, GiTakeMyMoney } from "react-icons/gi";
import { FaEye } from 'react-icons/fa6';




const Explore = () => {
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      try {
        // Intentional empty block
      } catch (err) {
        if (err.message === 'ResizeObserver loop completed with undelivered notifications.') {
          console.warn('ResizeObserver loop error detected.');
        } else {
          throw err;
        }
      }
    });

    observer.observe(document.body); // Assuming observing the body for changes

    return () => observer.disconnect();
  }, []);




const [Explore, setExplore] = useState([])







  const navigate = useNavigate()

const [userInfo, setUserInfo] = useState({});

useEffect(() => {
 try{


   const encryptedData = sessionStorage.getItem("userDataEnc");
   const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
   const decryptedData = AES.decrypt(encryptedData, encryptionKey);
   const decryptedString = decryptedData.toString(enc.Utf8);
   const parsedData = JSON.parse(decryptedString);
     setUserInfo(parsedData);


 }catch(error){
  navigate("/")
 }

}, []);





useEffect(() => {
  const formData = new FormData();
  formData.append("AdminId", userInfo.UserId);

  fetch(apiServer + "ViewCollectionAccount", {
    method: "POST",
     headers: {
      'UserId': userInfo.UserId,
      'SessionId': userInfo.SessionId,
      "ShortName":ShortName
    },
    body: formData
  })
    .then(res => res.json())
    .then(data => {
      // Check if data is an array and transform StartDate if it exists
      if (Array.isArray(data)) {
        const formattedData = data.map(item => {
          if (item.created_at) {
            return {
              ...item,
              StartDate: customDateFormat(item.created_at),
              LastActivityDate: customDateFormat(item.updated_at),
            };
          }
          return item;
        });
        setExplore(formattedData);
      } else {
        setExplore(data);
      }
    })
    .catch(err => console.error(err));
}, [userInfo]);

// Custom date format function
const customDateFormat = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };
  return date.toLocaleString('en-US', options);
};





// Define the menu items array
const menuItems = [
  {
    icon: <FaEye />,
    text: "View History",
    type: "navigate",
    path: `/main/collectionAccount/:AccountId`, // Placeholder for the dynamic segment
  },

  {
      icon: <GiTakeMyMoney />,
      text: "Collect Payment",
      type: "function",
      onClick: (AccountId) => {
        handleCreate(AccountId); // Assuming this function is defined in your component
      },
      columnNames: ['AccountId'] // Specify the column name for the ID here
    },


];




 const exploreGrid = [
  { accessorKey: "id", header: "ID" },
  { accessorKey: "UserId", header: "User ID" },
  { accessorKey: "OrderId", header: "Order ID" },
  { accessorKey: "AccountId", header: "Account ID" },
  { accessorKey: "FullName", header: "FullName" },
  { accessorKey: "Phone", header: "Phone" },
  { accessorKey: "Email", header: "Email" },
  { accessorKey: "Debit", header: "Principal Amount" },
  { accessorKey: "AmountToPay", header: "Installment Amount" },
  { accessorKey: "Balance", header: "Outstanding Balance" },
  { accessorKey: "Status", header: "Status" },
  { accessorKey: "StartDate", header: "StartDate" },
  { accessorKey: "LastActivityDate", header: "LastActivityDate" },
  
];

 const exploreMediaGrid = [

];

  const handleCreate = async (AccountId) => {

 

    Show.showLoading("Processing Data");
      try {
    
    const formData = new FormData()
    
    formData.append("AdminId",userInfo.UserId)
    formData.append("AccountId",AccountId);

    
    
    
    
        const response = await fetch(apiServer+"ScheduleSinglePayment", {
          method: "POST",
          headers: {
            'UserId': userInfo.UserId,
            'SessionId': userInfo.SessionId,
            "ShortName":ShortName
          },
          body:formData
        });
    
        const data = await response.json();
     
    
        if (response.ok) {
          
          Show.hideLoading();
    
          Show.Success(data.message);
           window.location.reload()
          
        } else {
          Show.Attention(data.message);
        }
      } catch (error) {
    
        Show.Attention("An error has occured");
       
      }
    
    }







  return (
    <div>
      <Header category="E-commerce Mgmt" title="Collection Account" />



      <div style={{ marginTop: "2rem", padding: "1rem" }}>
        <span>
          <u
            style={{
              color: localStorage.getItem("colorMode"),
              textAlign: "center",
              fontSize: "1.5rem",
            }}
          >
          Collection Account List
          </u>
        </span>

        <HydotTable 
  columns={exploreGrid} 
  data={Explore} 
  media={exploreMediaGrid} 
  colorMode={localStorage.getItem("colorMode")}
  menuItems={menuItems}

/>;

       
      </div>
    </div>
  );
}

export default Explore;

